.HeaderDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.HeaderInner {
  width: 95%;
  height: 45px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderInner nav {
  width: 83%;
  margin-left: 50px;
}

.HeaderInner ul {
  width: 100%;
  padding: 0;
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  font-size: 18px;
}

.nowLink {
  color: #3386FF !important;
}

.HeaderInner li::marker {
  display: none;
}

.logoLink {
  height: 100%;
}

.logoLink img {
  height: 100%;
}

.HeaderLine {
  width: 100vw;
  height: 1px;
  background: #DCDCE6;
  flex-shrink: 0;
}

.logout {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  font-size: 14px;
}

.logout a {
  color: #3E3E3E !important;
}