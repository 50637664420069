.menuDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #DCDCE6;
}

.superLisDiv {
  width: 100%;
  border-bottom: 1px solid #DCDCE6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.superLisDiv:last-of-type {
  border-bottom: 0;
}

.superTitleDiv, .subTitleDiv {
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.superTitleDiv {
  width: 100%;
  border-bottom: 1px solid #DCDCE6;
  font-size: 20px;
  color: #191919;
  font-weight: bold;
}

.subTitleDiv {
  width: 60%;
  border-bottom: 1px solid #DCDCE6;
  font-size: 18px;
  color: #191919;
}

.superLisDiv .subTitleDiv:last-child {
  border-bottom: 0;
}

.selected {
  color: #3386FF;
}

.empty {
  border: 0;
}