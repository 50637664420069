.calendar {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-top: 2px solid #8F969F;
}

.calendar td {
  border: 1px solid #DCDCE6;
  padding: 0;
}

.calendar thead {
  height: 40px;
  background: #F8F8FA;
}

.calendar tbody td {
  height: 110px;
  position: relative;
  width: 14.2857%;
}

.calendar thead td:first-child, .calendar tbody td:first-child .dayText {
  color: #E24A4A;
}

.calendar thead td:last-child, .calendar tbody td:last-child .dayText {
  color: #0052C8;
}

.dayText {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  z-index: -1;
}

.dayContents {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}