.reservationList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reservationList select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
}

.radioDiv input[type="radio"] {
  margin: 0px 5px 0px 0px;
  width: 20px;
  height: 20px;
}

.radioDiv label {
  margin-right: 40px;
  font-size: 18px;
  color: #3E3E3E;
}

.waveDiv {
  font-size: 18px;
  margin: 0px 10px 0px 10px;
}