.goodInfoDiv, .innerMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  color: #3386FF;
}

.defaultSelect {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
  margin-right: 10px;
}

.length {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.length div {
  display: flex;
  align-items: center;
}

.length input[type="radio"] {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.length label {
  margin-right: 25px;
  font-size: 18px;
}

.length > div > div {
  column-gap: 5px;
}

.length > div > div > div {
  font-size: 18px;
  margin-right: 10px;
}

.imageDiv img {
  max-width: 500px;
  max-height: 300px;
}

.detailImageDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
}

.detailImageDiv > div:last-of-type {
  display: flex;
  width: 100%;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 20px;
}

.detailImageDiv > div > div {
  width: 24%;
  flex-shrink: 0;
  min-height: 60px;
}

.detailImageDiv > div > div > img {
  width: 100%;
  margin-bottom: 5px;
}

.leftRightInner {
  width: 100%;
  display: flex;
  column-gap: 30px;
}

.leftRightInner > div:first-child {
  width: 60%;
}

.leftRightInner > div:last-child {
  width: 40%;
}

.hotelListDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.hotelListDiv > .defaultSelect {
  width: 100%;
  height: 50px;
  margin: 0;
}

.leftRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.leftRight select {
  width: 100px;
  height: 40px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
  margin-right: 10px;
}

.calendarEach {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  row-gap: 5px;
}

.calendarEach a {
  color: #0052C8 !important;
}

.calendarEach button {
  font-size: 14px;
}

.schedule > div {
  margin: 0 !important;
}

.schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  font-size: 14px;
}

.addDiv {
  width: 900px;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
}

.buttonsDiv {
  display: flex;
  justify-content: center;
}