.mainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  margin-right: 5px;
}

.line {
  display: flex;
  align-items: center;
  width: 100%;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.checks input[type="checkbox"] {
  margin-right: 5px;
}

.checks label {
  margin-right: 15px;
}

.flexList {
  display: flex;
  width: 100%;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.eachDate {
  display: flex;
  column-gap: 3px;
}

.mainDiv select {
  width: 100px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
}

.schedule {
  display: flex;
  column-gap: 10px;
}

.mini {
  font-size: 13px;
  line-height: 100%;
  padding: 5px;
  color: white;
}

.lines {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.schedule input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}

select.trans {
  width: 120px !important;
}

.isAir {
  width: 70px !important;
}

.inTable {
  display: flex;
  justify-content: center;
  column-gap: 4px;
}