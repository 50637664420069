.mainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  color: #3386FF;
}

.mainDiv select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
  margin-right: 10px;
}

.mainDiv textarea {
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding: 10px;
}

.withButton {
  display: flex;
}

.modalDiv {
  width: 800px;
  padding: 50px;
  background: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tableWrapper {
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
}

.tableWrapper thead {
  position: sticky;
  top: 0;
}

.tableEnding {
  position: sticky;
  bottom: 0;
  background: #F8F8FA;
}

.tableEnding td:first-child {
  color: #3386FF;
}