.userListDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userListDiv select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
}

.userListDiv input[type="checkbox"] {
  margin: 0px 5px 0px 0px;
  width: 20px;
  height: 20px;
}

.userListDiv label {
  margin-right: 40px;
  font-size: 18px;
  color: #3E3E3E;
}

.buttonsDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin: 80px 0px 15px 0px;
}

.yesorno {
  font-size: 20px;
  line-height: 100%;
}

.yes {
  color: #3386FF;
}

.no {
  color: #191919;
}
