.mainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv input {
  font-size: 18px;
}

.littleTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 22px;
  font-weight: bold;
  color: #425571;
  margin-bottom: 10px;
  margin-left: 20px;
}

.mainDiv textarea {
  border: 1px solid var(---b6bac0);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B6BAC0;
  font-size: 18px;
  font-family: inherit;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 150px;
}

.mainDiv select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
}

.radiosDiv {
  display: flex;
}

.radiosDiv input {
  width: 25px;
  height: 25px;
  margin: 0px 8px 0px 0px;
}

.radiosDiv label {
  font-size: 18px;
  margin-right: 40px;
}

.buttonsDiv {
  display: flex;
}

.modalDiv {
  width: 900px;
  height: 90vh;
  background: white;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}

.modalInputDiv {
  display: flex;
  column-gap: 10px;
  flex-shrink: 0;
}

.modalResultDiv {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.modalResultDiv thead {
  position: sticky;
  top: 0;
}

.modalResultDiv tbody td {
  cursor: pointer;
}

.pageButtonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
}

.pageButtonDiv > div {
  font-size: 18px;
}