.loadmore {
    width: 150px;
    height: 40px !important;
    margin-top: 30px;
    margin-bottom: 20px;
    border: 1px solid #DCDCE6;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    flex-shrink: 0;
}

.noload {
    color: gray !important;
}
