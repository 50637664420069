.mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv input[type="radio"] {
  margin: 0px 5px 0px 0px;
  width: 20px;
  height: 20px;
}

.mainDiv label {
  margin-right: 60px;
  font-size: 18px;
  color: #3E3E3E;
}

.mainDiv input[type="date"] {
  width: 200px;
  height: 36px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  border: 1px solid #B6BAC0;
  background: white;
  color: #B6BAC0;
}

.mainDiv span {
  margin: 0px 8px 0px 8px;
}


.tableReadWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableRead {
  width: 80px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.tableReadYes {
  background: #3386FF;
  color: #FFFFFF;
}

.tableReadNo {
  border: 1px solid #8F969F;
  box-sizing: border-box;
  color: #4F5968;
}