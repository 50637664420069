.eventWrapper {
  width: 100%;
  height: 100%;
}
.newUserListDiv {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  justify-content: space-between;
}

.formDiv {
  width: 100%;
  flex-shrink: 0;
  background: #F1F3F7;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.formDiv input, .formDiv select {
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  border: 1px solid #B6BAC0;
  background: white;
  color: #444649;
  text-align: center;
  width: 120px;
  height: 36px;
}

.formDiv > button {
  width: 120px !important;
  height: 36px !important;
  margin-left: auto;
  background: #4F5968;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  border: 0;
}

.travelTime {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-left: 10px;
}

.travelTime > input {
  width: 50px;
}

.bottomWrapper {
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: row;
}

.leftWrapper {
  width: var(--left);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rightWrapper {
  width: var(--right);
  height: 100%;
}

.bottomSlider {
  width: 5px;
  height: 100%;
  flex-shrink: 0;
  cursor: col-resize;
  padding: 0px 10px 0px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomSlider > div {
  width: 5px;
  background: lightgray;
  height: 100%;
  flex-shrink: 0;
}

.leftInner {
  width: 100%;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  overflow-x: auto;
  overflow-y: auto;
}

.leftContent {
  width: 100%;
  min-width: 1300px;
  min-height: 100%;
  /* height: 10000px; */
}

.leftFooter {
  width: 100%;
  flex-shrink: 0;
  padding-top: 10px;
  box-sizing: border-box;
}

.textButton {
  display: inline;
  background: none;
  border: none;
  font-size: 18px;
  color: #3E3E3E;
  cursor: pointer;
}

.rightInner {
  width: 100%;
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
  overflow-y: auto;
}

.leftContent tbody tr {
  cursor: pointer;
}

.leftFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
}

.leftFooter > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.leftFooter > div > div {
  font-size: 20px;
}

.leftContent table input[type="checkbox"] {
  width: 24px;
  height: 24px;
}