.sidebarDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #DCDCE6;
  box-sizing: border-box;
}

.logoLink > div {
  width: 90px;
  height: 90px;
  border-bottom: 1px solid #DCDCE6;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoLink > div > img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.sideMenuDiv {
  width: 100%;
  height: calc(100% - 180px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden !important;
  flex-wrap: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* padding-bottom: 300px;
  margin-bottom: -300px; */
}

.sideMenuDiv:hover {
  padding-right: 184px;
  margin-right: -184px;
}

.sideMenuDiv::-webkit-scrollbar {
  display: none;
}

.tile {
  width: 90px;
  height: 90px;
  border-bottom: 1px solid #DCDCE6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  flex-shrink: 0;
  /* position: relative; */
}

.tile > div {
  font-size: 18px;
  font-weight: 600;
}

.dark {
  background: #4F5968;
  color: white;
  border: 0;
  /* z-index: 200; */
}

.tileLinke {
  /* z-index: 20; */
}

.tileNow {
  color: #3386FF !important;
}

.tileNow svg path {
  stroke: #3386FF !important;
}

.tileWrapper:hover .tile {
  border-right: 3px solid #3386FF;
}

.subList {
  display: none;
  position: absolute;
  left: 90px;
  top: 0;
  /* z-index: 10; */
  width: 184px;
  border: 1px solid #DCDCE6;
  background: #F1F3F7;
  box-sizing: border-box;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
}

.tileWrapper:hover .subList, .subList:hover {
  display: flex;
}

.tileWrapper {
  position: relative;
}

.tileWrapper:last-of-type .subList, .tileWrapper:nth-last-of-type(2) .subList {
  top: auto !important;
  bottom: 0 !important;
}

.subLink, .subSuper {
  font-weight: 500;
  font-size: 18px;
}

.subSuper {
  color: gray
}

.subSuper:only-of-type {
  display: none;
}

.subNow {
  color: #3386FF !important;
}