.NewUserTravelDiv {
  width: 100%;
}

.NewUserTravelDiv input {
  height: 36px;
}

.inputsDiv {
  padding: 20px 60px 20px 60px;
  background: #F1F3F7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2fr 4fr 1fr 2fr 4fr;
  column-gap: 30px;
}

.inputsDiv > div {
  font-size: 18px;
  font-weight: bold;
  color: #3E3E3E;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.buttonsDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.modalDiv {
  width: 700px;
  height: 90vh;
  background: white;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
}

.modalWindow {
  width: 100%;
  background: #F1F3F7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 4fr;
  column-gap: 30px;
  padding: 20px;
  box-sizing: border-box;
}

.modalWindow > div {
  font-size: 20px;
  font-weight: bold;
  color: #3E3E3E;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.modalTableWrapper {
  width: 100%;
  height: 50vh;
  overflow-y: auto;
}

.modalTableWrapper thead {
  position: sticky;
  top: 0;
}