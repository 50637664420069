.goodListDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goodListDiv select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
  margin-right: 10px;
}

.goodListDiv img {
  height: 100px;
  width: 150px;
  margin: 5px;
  object-fit: contain;
}

.deleteButton {
  width: 50px;
  height: 32px;
  border: 1px solid #C7CACF;
  border-radius: 5px;
  background: white;
  font-size: 14px;
  color: #3E3E3E;
}