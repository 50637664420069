.topDiv {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.sidebarDiv {
  width: 90px;
  height: 100%;
  flex-shrink: 0;
  position: relative;
}

.sidebarInner {
  width: 90px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 10; */
}

.contentDiv {
  width: calc(100% - 90px);
  min-height: 100%;
  padding: 30px;
  box-sizing: border-box;
}