.mainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv input {
  width: 250px;
  height: 36px;
}

.mainDiv select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
  margin-right: 10px;
}

.toEnd {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.content {
  width: 100%;
  padding: 50px;
  border: 2px solid #8F969F;
}

.title {
  font-size: 20px;
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.titleInput input {
  width: 600px !important;
}