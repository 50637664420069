.mainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
}

.selectedBtn {
  color: #3386FF;
}

.allLeft {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.tableImg {
  max-width: 200px;
  max-height: 100px;
}

.tableImg > img {
  max-width: 200px;
  max-height: 100px;
}

.bannerModal {
  width: 600px;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
}

.imageInputDiv {
  width: 100%;
}

.imageInputDiv img {
  max-width: 200px;
  max-height: 100px;
}

.modalSearch {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
}

.modalResult {
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalResult thead {
  position: sticky;
  top: 0;
}

.lines {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.lines > div {
  min-height: 1px;
}

.iconInputDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.iconInputDiv img {
  max-width: 60px;
  max-height: 60px;
}

.icon {
  max-width: 60px;
  max-height: 60px;
}

.buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px 10px 0px;
  row-gap: 5px;
}

.nowRow {
  background: #F8F8FA;
}

.company input {
  width: 500px;
  height: 50px;
}