.consultingListDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionSelectDiv {
  width: 100%;
  display: flex;
  column-gap: 30px;
  margin: 30px 0px 15px 10px;
}

.eachOptionDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.eachOptionDiv div {
  font-size: 20px;
  font-weight: bold;
  color: #3E3E3E;
}