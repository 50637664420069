.selectWindowDiv {
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-left: 15px;
}

.selectWindowDiv > div {
  display: flex;
  width: 100%;
}

.selectWindowDiv > div > div {
  width: 100%;
  display: flex;
  column-gap: 40px;
  align-items: center;
  flex-shrink: 1;
}

.selectWindowDiv > div > div > div:first-of-type {
  width: var(--leftWidth);
  font-size: 18px;
  font-weight: bold;
  color: #3E3E3E;
  flex-shrink: 0;
}

.selectWindowDiv > div > div > div:last-of-type {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.line {
  width: 100%;
  height: 1px;
  background: #DCDCE6;
}

.line:last-child {
  display: none;
}