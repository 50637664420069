.NewUserInfoEditDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px;
  box-sizing: border-box;
}

.InfoWrapper {
  width: 100%;
  container-type: inline-size;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.InfoWrapper > form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  column-gap: 30px;
  row-gap: 20px;
}

.InfoWrapper > form > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.InfoWrapper > form > div {
  font-size: 18px;
  font-weight: bold;
  color: #3E3E3E;
  flex-shrink: 0;
}

.InfoWrapper > form > input, .InfoWrapper > form > select {
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  border: 1px solid #B6BAC0;
  background: white;
  color: #444649;
  text-align: center;
  width: 100%;
  height: 36px;
}

.InfoWrapper > form > textarea {
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  border: 1px solid #B6BAC0;
  background: white;
  color: #444649;
  text-align: center;
  width: 100%;
}

.wide {
  grid-column: 2 / 5;
  width: 100% !important;
}

@container (max-width: 540px) {
  .InfoWrapper > form {
    grid-template-columns: 1fr 2fr;
  }

  .wide {
    grid-column: 2 / 3;
  }
}

.TravelWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.buttonDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}