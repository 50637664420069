.expenseListDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionListDiv {
  width: 100%;display: flex;
  align-items: center;
  font-size: 20px;
  column-gap: 10px;
  margin: 20px 0px 50px 0px;
}

.blueButton {
  height: 40px;
  padding: 0px 15px 0px 15px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #3386FF;
  background: white;
  color: #3386FF;
}

.infoListDiv {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  width: 100%;
}

.infoListDiv > div {
  width: 100%;
  display: flex;
  column-gap: 30px;
}

.infoListDiv > div > div:first-child {
  font-size: 22px;
  font-weight: bold;
  color: #425571;
  width: 82px;
  flex-shrink: 0;
}

.infoListDiv > div > div:last-child {
  width: 100%;
}

.modal {
  background: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal select {
  width: 120px;
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
  margin-right: 10px;
}

.lastTable td {
  border-top: 2px solid #4F5968 !important;
}