.mainDiv {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #DCDCE6;
}

.mainDiv button {
  width: 120px;
  height: 50px;
  border: 1px solid #DCDCE6;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 0;
  background: white;
  font-size: 18px;
}