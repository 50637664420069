.loginDiv {
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginTitle {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #191919;
}

.loginDiv form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginInfoDiv {
  width: 100%;
  height: 45px;
  border: 1px solid #DCDCE6;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 17px;
  padding: 7px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.loginInfoDiv svg {
  width: 30px;
  height: 30px;
  fill: #707070;
}

.loginInfoDiv input {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  font-size: 16px;
  color: #3E3E3E;
}

.loginDiv button {
  margin-top: 50px;
  width: 100%;
  height: 45px;
  background: #3386FF;
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
}