.mainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.mainDiv select {
  height: 36px;
  border: 1px solid #B6BAC0;
  background: white;
  font-size: 16px;
  color: #3E3E3E;
  padding-left: 5px;
}

.mainDiv select:not(table select) {
  width: 120px;
}

.leftBlank {
  width: 100%;
  padding-left: 10px;
}

.leftBlank > div > div > div:last-child {
  font-size: 20px;
}

.korean, .abroad {
  font-size: 16px;
  border: 1px solid;
  padding: 4px;
}

.korean {
  color: #3386FF;
  border-color: #3386FF;
}

.abroad {
  color: #FF4E40;
  border-color: #FF4E40;
}

.buttons {
  display: flex;
  column-gap: 10px;
}