.themeInput {
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  border: 1px solid #B6BAC0;
  background: white;
  color: #444649;
}

.themeInput:disabled {
  background: #ced2d8;
  color: black;
}